<template>
  <div class="quality-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item><i class="icon-home"></i>质检中心</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="search-box">
        <Row>
          <Col span="24">
            <h2 class="search-title border-bottom">按条件搜索</h2>
          </Col>
        </Row>
        <Form class="search-form" :label-width="120">
          <Row>
            <Col span="5">
              <Form-item label="上次抽查日期：">
                <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="会员姓名：">
                <Input v-model="searchForm.memble_name" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="5">
              <Form-item label="省份：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
                <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                  <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
                <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
                <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <div class="tac">
            <Button type="primary" class="search-btn" @click="handleSubmit()">搜索</Button>
          </div>
        </Form>
      </div>
      <div class="filter-list-tpl">
        <Table stripe :columns="columns" :data="qualityList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="12">
          <div class="table-oprate">
            <!--<Button @click="delBoost" v-if="this.power.indexOf('医生--删除促进记录的权限') > -1">删除</Button>-->
          </div>
          </Col>
          <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import qualityService from '@/services/qualityService';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			searchForm: {
				page: 1,
				size: 10,
				start_time: '',
				end_time: '',
				memble_name: '',
				pid: '',
				cid: '',
				sid: '',
			},
			columns: [
				// { type: 'selection', width: 60, align: 'center' },
				{ title: '上次抽查日期', key: 'create_time', align: 'center' },
				{ title: '顾客类型', key: 'new_member_type', align: 'center' },
				{ title: '会员姓名', key: 'member_name', align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{ title: '联系电话', key: 'mobile', align: 'center' },
				{
					title: '抽查记录',
					key: '',
					width: 120,
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										margin: '0 10px',
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href: '#/base/quality/list/' + this.qualityList[params.index].member_id,
									},
								},
								'查看',
							),
						]);
					},
				},
				{
					title: '操作',
					key: 'action',
					width: 120,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('质检中心--添加促进任务的权限') > -1) {
							return h('div', [
								h(
									'a',
									{
										style: {
											margin: '0 10px',
											color: '#ff8400',
										},
										attrs: {
											target: '_blank',
											href: '#/base/quality/start/' + this.qualityList[params.index].member_id,
										},
									},
									'抽查',
								),
							]);
						} else {
							return h('div', [
								h(
									'a',
									{
										style: {
											margin: '0 10px',
											color: '#ff8400',
										},
										on: {
											click: () => {
												this.$Message.warning('抱歉，你暂没有该权限');
											},
										},
									},
									'抽查',
								),
							]);
						}
					},
				},
			],
			qualityList: [],
			pageTotal: 0,
			fpmodal: false,
			power: '',
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getQualityList();
		qualityService.getQualitySelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getQualityList() {
			qualityService.getQualityList(this.searchForm).then((data) => {
				this.qualityList = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit() {
			this.searchForm.page = 1;
			this.getQualityList();
		},
		changeTime(date) {
			this.searchForm.start_time = date[0];
			this.searchForm.end_time = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getQualityList();
		},
	},
};
</script>

<style lang="css" scoped>
</style>
